import { listMenu } from '~/constants/menu'
import { permissionPage } from '~/constants/permission'

export function useMenu(module?: string) {
  const nuxt = useNuxtApp()

  let menus = listMenu(module)

  // Set default expansion panel to false
  menus = menus.map((menu: any) => {
    menu.active = true

    if (menu.link == null || menu.module == '/')
      menu.hasAccess = true
    else
      menu.hasAccess = nuxt.$hasAccess(`${permissionPage} - ${menu.module}`)

    if (menu.children && menu.children.length > 0) {
      menu.children = menu.children.map((child: any) => {
        child.active = true

        child.hasAccess = child.module ? nuxt.$hasAccess(`${permissionPage} - ${child.module}`) : true

        if (child.children && child.children.length > 0) {
          child.children = child.children.map((grandChild: any) => {
            grandChild.hasAccess = nuxt.$hasAccess(`${permissionPage} - ${grandChild.module}`)
            return grandChild
          })
        }

        return child
      })
    }
    return menu
  })

  menus = menus.filter((menu: any) => {
    if (menu.hasAccess === false)
      return false
    if (menu.children && menu.children.length > 0) {
      menu.children = menu.children.filter((child: any) => {
        if (child.hasAccess === false)
          return false
        if (child.children && child.children.length > 0) {
          menu.children = menu.children.filter((grandChild: any) => {
            if (grandChild.hasAcess === false)
              return false
            if (grandChild.children && grandChild.children.length > 0) {
              grandChild.children = grandChild.children.filter((microChild: any) => {
                if (microChild.hasAccess === false)
                  return false
                return true
              })
              if (grandChild.children.length === 0)
                return false
            }
            return true
          })
        }
        return true
      })
      if (menu.children.length === 0)
        return false
    }
    else if (menu.link) {
      return true
    }
    else {
      return false
    }
    return true
  })

  return menus
}
